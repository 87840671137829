import React, { useContext, useState } from 'react';
import { graphql } from 'gatsby';
import tw from 'twin.macro';
import IndicatorPreview from '../molecules/indicator-preview';
import Sandbox from '../atoms/sandbox';
import Breadcrumb from '../atoms/breadcrumb';
import AppContext from '../common/context';
import Curve from '../svgs/curve';
import EuropeMap from '../../images/europe.inline.svg';
import { motion } from 'framer-motion';
import InView from 'react-intersection-observer';
import IndiButton from '../atoms/indicator-button';
import '../../styles/gradients.css';

const IndicatorButton = motion(IndiButton);

const MapGateway = ({ data }) => {
  const { indicators } = useContext(AppContext);
  const [openIndicator, setIndicator] = useState(null);
  const closePreview = () => setIndicator(null);
  return (
    <>
      <div
        tw="relative py-6 sm:(pt-12 pb-20) lg:(pt-20 pb-28) xl:(pt-24 pb-36)"
        className="bg-gradient-brand-purple"
      >
        <Curve tw="absolute w-full bottom-full left-0 right-0" fill="#41336a" />
        <div tw="absolute inset-0 overflow-hidden">
          <EuropeMap
            css={[
              tw`absolute bottom-0 right-0 w-full text-brand-purple-600 text-opacity-10 `,
              tw`md:(h-full w-auto)`,
            ]}
            role="presentation"
          />
        </div>
        <div tw="max-w-screen-lg mx-auto px-6 text-center sm:(px-20) lg:(px-28) xl:(text-left pl-20 pr-40) 2xl:pl-0">
          {data.breadcrumb && (
            <Breadcrumb text={data.breadcrumb} theme="dark" tw="mb-8" />
          )}
          <h3 tw="text-3xl leading-none font-black text-brand-green mb-6 lg:text-4xl">
            {data.title}
          </h3>
          <Sandbox
            html={data.intro}
            tw="text-white font-semibold mb-6 text-lg leading-tight xl:text-xl"
          />
          <Sandbox html={data.body} tw="text-white xl:text-lg" />
          <InView>
            {({ inView, ref }) => (
              <motion.div
                tw="flex flex-wrap justify-center mt-10 lg:mt-16 xl:(justify-start)"
                initial="out"
                animate={inView ? 'in' : 'out'}
                transition={{ staggerChildren: 0.1 }}
                ref={ref}
              >
                {indicators.map((x, index) => (
                  <IndicatorButton
                    key={x.slug}
                    tw="w-36 h-36 -mx-2 -mt-2 xl:text-lg xl:leading-none xl:p-6 xl:w-40 xl:h-40 2xl:(w-44 h-44)"
                    item={x}
                    index={index}
                    onClick={() => setIndicator(x)}
                    variants={{
                      out: { y: 100, opacity: 0 },
                      in: { y: 0, opacity: 1 },
                    }}
                    transition={{ ease: 'backOut', duration: 1.2 }}
                  />
                ))}
              </motion.div>
            )}
          </InView>
        </div>
      </div>
      <IndicatorPreview data={openIndicator} onClose={closePreview} />
    </>
  );
};

export const query = graphql`
  fragment MapGateway on STRAPI_ComponentContainersMapGateway {
    id
    title
    intro
    body
    breadcrumb
  }
`;

export default MapGateway;
