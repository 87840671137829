import React from 'react';
import tw, { css } from 'twin.macro';
import ArrowRight from '../../images/icons/arrow_right_alt.inline.svg';

const IndicatorButton = React.forwardRef(
  ({ item, index, onClick, className, Icon = ArrowRight, children }, ref) => (
    <button
      ref={ref}
      css={[
        tw`p-4 relative rounded-full
                leading-none text-center
                text-white
                font-semibold
                flex-shrink-0
                flex-grow-0
                hover:(bg-opacity-100 z-20 shadow-md)
                focus:(outline-none bg-opacity-100)`,
        index % 2 === 0
          ? tw`bg-brand-purple-800 bg-opacity-80`
          : tw`bg-brand-green bg-opacity-80`,
        css`
          transition: background-color 0.3s ease, box-shadow 0.3s ease;
        `,
      ]}
      className={className}
      onClick={onClick}
    >
      {item.name}
      {Icon && (
        <Icon tw="mx-auto mt-1 text-white text-opacity-50 absolute left-1/2 -ml-3" />
      )}
      {children}
    </button>
  )
);

export default IndicatorButton;
