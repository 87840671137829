import React from 'react';
import { Link, graphql } from 'gatsby';
import tw from 'twin.macro';

const buttonStyles = [
  tw`bg-brand-purple-800 text-white rounded-full mx-2 px-6 py-2 select-none font-black uppercase`,
];

const Action = ({ data, className, onClick }) => {
  const handleClick = () => {
    onClick?.();
    return true;
  };
  if (data.download?.localFile?.publicURL) {
    return (
      <a
        href={data.download.localFile.publicURL}
        css={buttonStyles}
        className={className}
        target="_blank"
        rel="noreferrer"
        title={data.download.alternativeText || data.download.name}
      >
        {data.label}
      </a>
    );
  } else if (data.is_external)
    return (
      <a
        href={data.url}
        css={buttonStyles}
        className={className}
        target="_blank"
        rel="noreferrer"
        onClick={handleClick}
      >
        {data.label}
      </a>
    );
  return (
    <Link to={data.url} css={buttonStyles} className={className}>
      {data.label}
    </Link>
  );
};

export const query = graphql`
  fragment Action on STRAPI_ComponentAtomsAction {
    id
    is_external
    label
    url
    download {
      url
      name
      alternativeText
      localFile {
        publicURL
      }
    }
  }
`;

export default Action;
