import React, { useEffect, useRef } from 'react';
import tw, { css } from 'twin.macro';
import Portal from '../common/portal';
import Icon from '../svgs/icon';
import Sandbox from '../atoms/sandbox';
import Simplebar from 'simplebar-react';
import { AnimatePresence, motion } from 'framer-motion';
import {
  enableScrollLock,
  clearScollLock,
} from '../../utils/use-body-scroll-lock';
import Action from '../atoms/action';
import CloseIcon from '../../images/icons/close.inline.svg';
import '../../styles/overflow-fade.css';
import 'simplebar/dist/simplebar.min.css';
import { useContext } from 'react';
import AppContext from '../common/context';

const PreviewAction = tw(Action)`m-1`;

const IndicatorPreview = ({ data, onClose }) => {
  const ref = useRef();
  const { map } = useContext(AppContext);
  useEffect(() => {
    if (data) {
      enableScrollLock(ref.current ? ref.current.getScrollElement() : true);
      return () => clearScollLock();
    }
  });
  return (
    <Portal>
      <AnimatePresence>
        {data && (
          <motion.div
            tw="fixed inset-0 w-full z-50 flex items-center justify-center"
            initial="out"
            animate="in"
            exit="exit"
            variants={{
              exit: {
                transition: {
                  staggerDirection: -1,
                },
              },
            }}
            transition={{
              staggerChildren: 0.1,
            }}
          >
            <motion.div
              variants={{
                out: { opacity: 0 },
                in: { opacity: 1 },
                exit: { opacity: 0 },
              }}
              tw="fixed inset-0 w-full bg-brand-purple-400 bg-opacity-60"
            ></motion.div>
            <motion.div
              css={[
                tw`w-10/12 max-w-screen-sm px-2 text-center bg-white relative z-50`,
              ]}
              className="overflow-fade-white"
              variants={{
                out: { scale: 0, opacity: 0 },
                in: { scale: 1, opacity: 1 },
                exit: { y: '200%', opacity: 0 },
              }}
            >
              <Simplebar
                autoHide={true}
                ref={ref}
                css={[
                  tw`overflow-y-auto overflow-x-hidden`,
                  css`
                    max-height: 70vh;
                    .simplebar-scrollbar:before {
                      ${tw`bg-brand-purple-200`}
                    }
                  `,
                ]}
              >
                <div tw="px-4 py-12">
                  {data.icon && (
                    <Icon name={data.icon} tw="w-12 h-12 mx-auto mb-4" />
                  )}
                  <h3 tw="font-bold text-2xl leading-none text-brand-green mb-6">
                    {data.name}
                  </h3>
                  <Sandbox html={data.summary} />
                  <div tw="flex items-center flex-wrap justify-center mt-6 -mx-1">
                    <PreviewAction
                      data={{
                        label: 'Read more',
                        url: `/indicators/${data.slug}`,
                      }}
                    />
                    <PreviewAction
                      data={{
                        label: 'Show on map',
                        url: `/${map.slug}?i=${data.slug}`,
                      }}
                    />
                  </div>
                </div>
              </Simplebar>
              <button
                tw="absolute top-0 right-0 rounded-full bg-brand-purple-800 text-brand-purple-100 focus:outline-none z-20 p-3 -mr-4 -mt-4"
                onClick={onClose}
              >
                <CloseIcon />
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default IndicatorPreview;
